import axios from "../axios";
import { GetUserApi } from "./types/user";
import { errorHandler } from "../utils/helpers/apis";
import {
  ErrorRerunPayload,
  GenerateProductDebugPayload,
  GetRainForestProductPayload,
  GoGoProductPayload,
  ProductGeneratePayload,
  publishOnAmazonPayload,
  ScrapeProductApi,
  UnattachedProductPayload,
  UpdateProductBrandPayload,
  UpdateProductDomainPayload,
  UpdateSelectedCompetitorsPayload,
} from "./types/product";

export const generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription = async ({
  isRerun = false,
  productASIN,
  titleKeywords,
  otherKeywords,
  userID,
  searchTermKeywords,
}: ProductGeneratePayload) => {
  return await axios.post(`/generated-product/product-generate`, {
    isRerun,
    productASIN,
    titleKeywords,
    otherKeywords,
    userID,
    searchTermKeywords,
  });
};

export const ProductApis = {
  async generateProductDebug(obj: GenerateProductDebugPayload) {
    return axios
      .put<any, any>(`generated-product/debug-mode`, obj)
      .then((r: GetUserApi) => r.data.data)
      .catch(errorHandler);
  },
  async generateUnattachedProduct(product: UnattachedProductPayload) {
    return axios
      .post<UnattachedProductPayload, ScrapeProductApi>(`product/new-amazon-product`, {
        productDetail: product,
      })
      .then((r: ScrapeProductApi) => r.data.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },
  async getRainForestProduct({ domain, asin, isGoGoAutomato, userID }: GetRainForestProductPayload) {
    return axios
      .get<GetRainForestProductPayload, ScrapeProductApi>(
        `/product/${asin}/${domain}?isGoGoAutomato=${isGoGoAutomato}${userID ? `&userID=${userID}` : ""}`
      )
      .then((r: ScrapeProductApi) => r.data.data);
  },
  async getRainForestProductRatings({ asin }: any) {
    return axios.get<any, any>(`/generated-product/product-latest-history/${asin}`).then((r: any) => r.data.data);
  },
  async updateAllCompetitorsByProductASIN(productASIN: string, userID: string, force: boolean, isRetry?: boolean) {
    return axios
      .put(`/product/update-all-competitors/${productASIN}/${userID}/${force}${isRetry ? `?isRetry=true` : ""}`)
      .then((r: ScrapeProductApi) => r.data.data);
  },
  async updateProductBrandByProductASIN({ productASIN, brand }: UpdateProductBrandPayload) {
    return axios.put(`/product/update-brand/${productASIN}?brand=${brand}`).then((r: any) => r.data.data);
  },
  async updateSelectedCompetitorsByProductASIN(payload: UpdateSelectedCompetitorsPayload) {
    return axios.put(`/product/update-competitors`, payload).then((r: any) => r.data.data);
  },
  async gogoAutomatoProductGeneration({
    domain,
    asin,
    userID = "",
    isRerun = false,
    updateCompetitors = false,
  }: ErrorRerunPayload) {
    return axios
      .get<ErrorRerunPayload, ScrapeProductApi>(
        `/product/go-go-auto-mato/${asin}/${domain}?userID=${userID}&isRerun=${isRerun}&updateCompetitors=${updateCompetitors}`
      )
      .then((r: any) => r.data.data);
  },
  async updateProductDomain({ productASIN, domain }: UpdateProductDomainPayload) {
    return axios.put(`/product/update-domain/${productASIN}?domain=${domain}`).then((r: any) => r.data.data);
  },
  async generateMultipleGogo(data: GoGoProductPayload) {
    return axios
      .post<GoGoProductPayload, any>(`product/bulk-products`, {
        ...data,
      })
      .then((r) => r.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },
  async getBulkGogoProducts(limit: number = 10, companyID: string = "") {
    return axios
      .get(`product/get-all-bulk-products?limit=${limit}${companyID ? `&companyID=${companyID}` : ""}`)
      .then((r) => r.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },

  async publishOnAmazon(data: publishOnAmazonPayload) {
    return axios
      .post<publishOnAmazonPayload, any>(`sellerpartner/patch`, data)
      .then((r: any) => r.data.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },

  // /sellerpartner/internal/publish/:userid
  async publishOnAmazonForInternalUsers(data: publishOnAmazonPayload, userID: string) {
    return axios
      .post<publishOnAmazonPayload, any>(`sellerpartner/internal/publish/${userID}`, data)
      .then((r: any) => r.data.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },
};
